body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4285;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cartlogo{
  width: 24px;
  height: 24px;
}
.cart{
  padding: 10px;
}
.navbarFont{
  font-family: 'Anton', sans-serif;
}
#profilePicture{
  height: 23px;
  width: 23px;
}
.logo{
  display: inline-block;
    flex-shrink: 0;
    color: rgb(204, 0, 0);
    fill: rgb(204, 0, 0);
    width: 48px;
    height: 48px;
    font-family: "Helvetica for Target", HelveticaForTarget, Targetica, "HelveticaNeue for Target", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.4285;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0px;
    text-indent: 0px;
    text-shadow: none;
    text-transform: none;
    vertical-align: baseline;
    white-space: normal;
}

.logo svg{
  height: 50px;
  width: 50px;
}
.logo{
  height: 50px;
  width: '10%';
  fill: rgb(204,0,0);
}

.productHeader{
  text-align: center;
  margin: 0 auto;
  font-size: 29px;
  font-weight: bold;
}
.product{
  display: flex;
  flex-direction: column;
  width: 252px;
  margin-bottom: 20px;
}

.productImage {
  width: 252px;
}

.productImage img {
  width: inherit;
  height: 336px;
}

.productName{
  font-weight: bold;
  font-size: 23px;
  margin: 0px;
}


.productPrice{
  font-size: 14px;
}

.ProductContainer{
  max-width: 1400px;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.addButton, .removeButton{
  border-radius: 4px;
  border: unset;
  background: rgb(204,0,0);
  padding: 5px;
  color: white;
  font-weight: bold;
}

.removeButton{
  background: yellow;
  color: rgb(204,0,0);
}

.cartDrawer{
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  transition: all 0.3s ease;
  margin-top: 4.5%;
}